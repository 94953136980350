import moment from "moment";
import { postCms } from "../../../classes/cmsApi";
import { licenceTypes } from "../data/licencesdata";

const addFieldPrefixToObjectKeys = (obj) => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [`field_${key}`, value]));
};

function parseFiles(files) {
  const file = [];
  const text = [];
  const textEn = [];
  files
    .filter((item) => {
      return !item.file_id;
    })
    .forEach((item, index) => {
      file[index] = item.file;
      text[index] = item.text;
      textEn[index] = item.text_en;
    });
  return { file, text, textEn };
}

const parseDateForDrupalFormat = (date) => {
  if (!date) return "";
  const parsedDate = moment(date, "DD-MM-YYYY HH:mm");
  return parsedDate.format("YYYY-MM-DDTHH:mm:ss");
};

export const saveDataSet = async (data, files, sampleFile, sampleFileChanged, datasetDescriptionFileLatvian,
  datasetDescriptionFileEnglish,
  datasetDescriptionFileLatvianChanged,
  datasetDescriptionFileEnglishChanged, type, isNew, default_geocoverage_term) => {
  const body = {
    ...addFieldPrefixToObjectKeys({ ...data, distribution_mode: type }),
    field_metadata_date: parseDateForDrupalFormat(data?.metadata_date),
    field_valid_from: parseDateForDrupalFormat(data?.valid_from),
    field_valid_to: parseDateForDrupalFormat(data?.valid_to),
  };

  const formData = new FormData();

  for (const key in body) {
    if (Object.prototype.hasOwnProperty.call(body, key)) {
      formData.append(key, body[key]);
    }
  }

  formData.append("type", "data_set_metadata");

  const parsedFiles = parseFiles(files);

  parsedFiles?.file?.forEach((file, index) => formData.append(`file[${index}]`, file));
  parsedFiles?.text?.forEach((text, index) => formData.append(`text[${index}]`, text));
  parsedFiles?.textEn?.forEach((textEn, index) => formData.append(`textEn[${index}]`, textEn));

  files
    .filter((item) => {
      return item.file_id && !item.can_restore;}
    )
    .forEach((item, index) => {
      formData.append(`changedFileIds[${index}]`, item.file_id);
      formData.append(`changedFileTexts[${index}]`, item.text);
      formData.append(`changedFileTextsEn[${index}]`, item.text_en);
    });

  if (sampleFileChanged) {
    formData.append("sample_file_changed", true);
    formData.append("sampleFile", sampleFile); // sample file would be null if needed to be deleted
  }

  if (datasetDescriptionFileLatvianChanged) {
    formData.append("dataset_desc_file_lv_changed", true);
    formData.append("descFileLv", datasetDescriptionFileLatvian);
  }

  if (datasetDescriptionFileEnglishChanged) {
    formData.append("dataset_desc_file_changed", true);
    formData.append("descFile", datasetDescriptionFileEnglish);
  }

  console.log("formData", formData);
  console.log("datasetDescriptionFileLatvian", datasetDescriptionFileLatvian, datasetDescriptionFileEnglish);
  console.log("datasetDescriptionFileLatvianChanged", datasetDescriptionFileLatvianChanged, datasetDescriptionFileEnglishChanged);


  const deletedFiles = data?.download_url
    ?.filter((item) => !files?.some((file) => file.target_id === item.target_id))
    .map((item) => item?.target_id)
    .filter((target_id) => target_id !== null);

  formData.append("delete_files", deletedFiles);

  const geographicalCoverage = formData.get("field_geographic_coverage_nuts");
  formData.set("field_geographic_coverage_nuts", geographicalCoverage ? geographicalCoverage : default_geocoverage_term?.key);

  // set model as schema for drupal, it will automatically get model from schema
  if (data?.data_format_schema)
    formData.set("field_format_model", data?.data_format_schema);

  try {
    if (isNew) {
      await postCms("api/v1/add/content", formData);
    } else {
      formData.append("id", body.field_nid);
      await postCms("api/v1/update/metadata", formData);
    }

    return true;
  } catch (error) {
    console.error("ERROR", error);
    throw error;
  }
};

export const getInitialData = (user, now) => {
  return {
    downloadable: true,
    valid_from: now,
    metadata_date: now,
    // metadata_contact_name: user?.juridicalPersonName,
    // metadata_contact_email: user?.email,
    publisher_email: user?.email,
    publisher_name: user?.givenName,
    publisher_website: user?.website,
    data_owner_name: user?.juridicalPersonName || "",
    data_owner_email: user?.email,
    licence_conditions: licenceTypes.find((obj) => obj.key === "open_data_commons_open_database_license_odbl")
      ?.key,
    usage_conditions: "free_open_data",
  };
};

export const parseExistingInitialData = (extraData, isNew, dates) => {
  const legal_framework = extraData.formData.legal_framework?.includes("sstp")
    ? "SSTP"
    : extraData.formData.legal_framework?.includes("srti")
    ? "SRTI"
    : extraData.formData.legal_framework?.includes("rtti")
    ? "RTTI"
    : "";

  return {
    ...extraData?.formData,
    legal_framework,
    valid_from: !isNew ? dates.valid_from : dates.now,
    valid_to: dates.valid_to,
    metadata_date: !isNew ? dates.add_date : dates.now,
  };
};

export const getDefVal = (copy, extraData, user, isNew) => {
  let dataFormat = "DD.MM.YYYY HH:mm";
  let dates = {
    now: moment().format(dataFormat),
    valid_to: extraData?.formData?.valid_to ? moment(extraData?.formData?.valid_to).format(dataFormat) : null,
    valid_from: extraData?.formData?.valid_from
      ? moment(extraData?.formData?.valid_from).format(dataFormat)
      : null,
    metadata_date: extraData?.formData?.metadata_date
      ? moment(extraData.formData.metadata_date).format(dataFormat)
      : null,
    add_date: moment(extraData?.formData?.add_date).format(dataFormat),
  };

  if (copy)
    return {
      ...extraData?.formData,
      valid_from: dates.now,
      valid_to: dates.valid_to,
      metadata_date: dates.now,
    };

  return isNew ? getInitialData(user, dates.now) : parseExistingInitialData(extraData, isNew, dates);
};
